import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_a = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - A'/>

      <h1>Legal Latin maxims and expressions</h1>
     <h2>A</h2>
    <LatQuotesIntro />

    <p><b>A mensa et thoro</b> - From bed and board.</p>
<p><b>A vinculo matrimonii</b> - From the bond of matrimony.</p>
<p><b>Ab extra</b> - From outside.</p>
<p><b>Ab initio</b> - From the beginning.</p>
<p><b>Absoluta sententia expositore non indiget</b> - An absolute judgment needs no expositor.</p>
<p><b>Abundans cautela non nocet</b> - Abundant caution does no harm.</p>
<p><b>Accessorium non ducit sed sequitur suum principale</b> - An accessory does not draw, but follows its principal.</p>
<p><b>Accessorius sequitur</b> - One who is an accessory to the crime cannot be guilty of a more serious crime than the principal offender.</p>
<p><b>Acta exteriora iudicant interiora secreta</b> - Outward acts indicate the inward intent.</p>
<p><b>Actio non accrevit infra sex annos</b> - The action has not accrued within six years.</p>
<p><b>Actio non datur non damnificato</b> - An action is not given to one who is not injured.</p>
<p><b>Actio personalis moritur cum persona</b> - A personal action dies with the person.</p>
<p><b>Actiones legis</b> - Law suits.</p>
<p><b>Actori incumbit onus probandi</b> - The burden of proof lies on the plaintiff.</p>
<p><b>Actus nemini facit injuriam</b> - The act of the law does no one wrong.</p>
<p><b>Actus non facit reum nisi mens sit rea</b> - The act does not make one guilty unless there be a criminal intent.</p>
<p><b>Actus reus</b> - A guilty deed or act.</p>
<p><b>Ad ea quae frequentius acciduunt jura adaptantur</b> - The laws are adapted to those cases which occur more frequently.</p>
<p><b>Ad hoc</b> - For this purpose.</p>
<p><b>Ad infinitum</b> - Forever, without limit, to infinity.</p>
<p><b>Ad perpetuam rei memoriam</b> - For a perpetual memorial of the matter.</p>
<p><b>Ad quaestionem facti non respondent judices; ad quaestionem legis non respondent juratores</b> - The judges do not answer to a question of fact; the jury do not answer to a question of Law.</p>
<p><b>Aedificare in tuo proprio solo non licet quod alteri noceat</b> - It is not lawful to build on one's own land what may be injurious to another.</p>
<p><b>Aequitas legem sequitur</b> - Equity follows the law.</p>
<p><b>Aequitas nunquam contravenit legem</b> - Equity never contradicts the law.</p>
<p><b>Alibi</b> - At another place, elsewhere.</p>
<p><b>Alienatio rei praefertur juri accrescendi</b> - Alienation is preferred by law rather than accumulation.</p>
<p><b>Aliunde</b> - From elsewhere, or, from a different source</p>
<p><b>Allegans contraria non est audiendus</b> - One making contradictory statements is not to be heard.</p>
<p><b>Allegans suam turpitudinem non est audiendus</b> - One alleging his own infamy is not to be heard.</p>
<p><b>Allegatio contra factum non est admittenda</b> - An allegation contrary to a deed is not to be heard.</p>
<p><b>Ambiguitas contra stipulatorem est</b> - An ambiguity is most strongly construed against the party using it.</p>
<p><b>Ambiguitas verborum patens nulla verificatione excluditur</b> - A patent ambiguity is never helped by averment.</p>
<p><b>Amicus curiae</b> - A friend of the Court.</p>
<p><b>Angliae jura in omni casu libertati dant favorem</b> - The laws of England are favorable in every case to liberty.</p>
<p><b>Animo furandi</b> - With an intention of stealing.</p>
<p><b>Animo testandi</b> - With an intention of making a will.</p>
<p><b>Annus luctus</b> - The year of mourning.</p>
<p><b>Ante</b> - Before.</p>
<p><b>Aqua currit et debet currere, ut currere solebat</b> - Water runs and ought to run.</p>
<p><b>Arbitrium est judicium</b> - An award is a judgment.</p>
<p><b>Arbor dum crescit; lignum cum crescere nescit</b> - A tree while it grows, wood when it cannot grow.</p>
<p><b>Argumentum ab auctoritate fortissimum est in lege</b> - An argument drawn from authority is the strongest in law.</p>
<p><b>Argumentum ab impossibilii plurimum valet in lege</b> - An argument from impossibility is very strong in law.</p>
<p><b>Argumentum ad hominem</b> - An argument directed a the person.</p>
<p><b>Argumentum ad ignoratiam</b> - An argument based upon ignorance (i.e. of one's adversary).</p>
<p><b>Arma in armatos sumere jura sinunt</b> - The laws permit the taking up of arms against the armed.</p>
<p><b>Assentio mentium</b> - The meeting of minds, i.e. mutual assent.</p>
<p><b>Assignatus utitur jure auctoris</b> - An assignee is clothed with rights of his assignor.</p>
<p><b>Audi alteram partem</b> - Hear the other side.</p>
<p><b>Aula regis</b> - The King's Court.</p>



   </Layout>
  )
}

export default LatQuotes_a
